import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader
} from "../../store/actions";

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }
        }
    }

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">

                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t('Menu')}</li>

                        <li>
                            <Link to="/dashboard">
                                <i className="ri-dashboard-line"></i>
                                {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
                                <span className="ms-1">Menu</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/usuarios">
                                <i className="ri-file-user-fill"></i>
                                <span className="ms-1">Usuários</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/protocolos">
                                <i className="ri-list-check-2"></i>
                                <span className="ms-1">Protocolos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/procedimentos">
                                <i className="ri-checkbox-multiple-blank-line"></i>
                                <span className="ms-1">Procedimentos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Drogas">
                                <i className="ri-hand-heart-fill"></i>
                                <span className="ms-1">Drogas</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/nutricao">
                                <i className="ri-cake-3-line"></i>
                                <span className="ms-1">Nutrição</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/exames-de-diagnóstico">
                                <i className="mdi mdi-speedometer-slow mdi-needle"></i>
                                <span className="ms-1">Exames de diagnóstico</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Eletrolitos">
                                <i className="mdi mdi-speedometer-slo mdi-pill"></i>
                                <span className="ms-1">Eletrólitos</span>
                            </Link>
                        </li>
                        
                        <li>
                            <Link className="has-arrow waves-effect" to="/#">
                                <i className="ri-drop-fill"></i>
                                <span className="ms-1">Hemoterapia</span>
                            </Link>
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/hemocomponentes">
                                        Hemocomponentes
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cuidados-especiais">
                                        Cuidados Especiais
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Reacoes-Transfusional">
                                        Reações Transfusionais
                                    </Link>
                                </li>
                        <li>

                            <Link className="has-arrow waves-effect" to="/#">
                               
                                <span className="ms-1"><strong>Coleta de células tronco hematopoéticas</strong></span>
                            </Link>
                            <ul className="sub-menu">
                               <li>
                                    <Link to="/mobilizacao">
                                    Mobilização
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/coleta-celulas-medula-ossea">
                                      Coleta de células da medula óssea
                                    </Link>
                                </li>
                            </ul>
                        </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default withRouter(connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader
})(withNamespaces()(SidebarContent)));
