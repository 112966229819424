import React, { Component } from "react"
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap";

import { toast } from 'react-toastify';

import api from '../../services/api';

//Import Breadcrumb
import "./datatables.scss"

class ModalLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbItems: [
        { title: "Menu", link: "/dashboard" },
        { title: "Cadastro", link: "#" },
      ],
      page: 1,
      sizePerPage: 10,
      productData: [],
      visible: false,
      modal_standard: false,
      modal_apagar: false,
      filtro: '',
      tituloTela: 'Nutrição',
      url: '/nutricao'
    }

    this.closeModal = this.props.closeModal.bind(this);
    this.controlarModalApagar = this.controlarModalApagar.bind(this);
 };

 componentDidMount() {
  this.buscarDados();
}

 getOptions() {
    let obj = '';
    if (localStorage.getItem("authUser")) {
      obj = JSON.parse(localStorage.getItem("authUser"));
    }

    const options = {
      headers: {"Authorization" : `Bearer ${obj.token}`}
    }

    return options;
  }

  async buscarDados() {
    const options = this.getOptions();
    const { data } = await api.get(`${this.state.url}`, options);
    this.setState({
      productData: data
    });
  }

  controlarModal() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }

  async cadastrarLink() {
    try {
      const item = this.getObjetoItem();
  
      if (!this.validarCampos(item)) {
        return false;
      }
  
      const options = this.getOptions();
      await api.post(`${this.state.url}`, item, options)
      .then(() => {
        this.closeModal();
        toast.success('Link cadastrado.');
        this.buscarDados();
      });

    } catch (error) {
        console.log(error)
      toast.error('Não foi possível cadastrar o item.');
    }
  }

  controlarModalApagar() {
    this.setState(prevState => ({
      modal_apagar: !prevState.modal_apagar
    }));

    this.removeBodyCss();
  }


  getValueId(nomeId) {
    return document.getElementById(nomeId).value;
  }
  
  getObjetoItem() {
    const item = {
      link: this.getValueId('link'),
      nomes: this.getValueId('nomes'),
      categorias: this.getValueId('categorias'),
      ativo: this.getValueId('ativo')
    };

    return item;
 }

 validarCampos(item) {
    const { link, nomes, categorias } = item;

    if (!nomes) {
      toast.error('Campo nome obrigatório');
      return false;
    }
    
    if (!categorias) {
      toast.error('Campo categoria obrigatório');
      return false;
    }

    if (!link) {
      toast.error('Campo Link obrigatório');
      return false;
    }


    return true;
  }

  render(){
    return (
        <>
         <Modal
          size="xl"
          isOpen={this.props.openModalCadastro}
          toggle={this.closeModal}
        >
           <ModalHeader>
            Link
           </ModalHeader>
           <ModalBody>
             <Row className="mb-3">
                    <Label className="form-label">Categoria</Label>
                    <Col md={12}>
                        <Input id="categorias" type="textarea"/>
                    </Col>
                </Row>
              <Row className="mb-3">
                  <Label className="form-label">Nome</Label>
                  <Col md={12}>
                    <Input id="nomes" type="textarea"/>
                  </Col>
              </Row>
              <Row className="mb-3">
                  <Label className="form-label">Link</Label>
                  <Col md={12}>
                    <Input id="link" type="textarea"/>
                  </Col>
              </Row>
              <Row className="mb-3">
                <Label className="form-label">Ativo</Label>
                <Col md={12}>
                  <select id="ativo" className="form-control">
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                </Col>
             </Row>
           </ModalBody>
           <ModalFooter>
            <Button
              type="button"
              onClick={this.closeModal}
              color="light"
              className="waves-effect"
            >
              Fechar
          </Button>
            <Button
              type="button"
              color="primary" className="waves-effect waves-light"
              onClick={() => this.cadastrarLink()}
            >
              Salvar
          </Button>
          </ModalFooter>
        </Modal>
            
        </>
        
    )
  }

}

export default ModalLink